import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  Injectable,
  input,
  signal,
  Signal,
} from '@angular/core';
import { ContentDensity } from '../list-item/list-item.types';

@Injectable({ providedIn: 'root' })
export class BdsListService {
  // The initial signal assignment was added for display in Storybook
  public contentDensity: Signal<ContentDensity> = signal('regular');
}

@Component({
  selector: 'bds-list',
  standalone: true,
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss',
  providers: [{ provide: BdsListService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
  private readonly listService = inject(BdsListService);

  /**
   * Sets the density mode for all the list items inside this list.
   * The content density is an indication how condensed the expected content of the list is (for example only status
   * pills). For breakpoints see the list-item component.
   * @type {InputSignal<ContentDensity>}
   * @default 'regular'
   */
  public contentDensity = input<ContentDensity>('regular', {
    alias: 'content-density',
  });

  constructor() {
    // Set instance-specific data
    this.listService.contentDensity = computed(() => this.contentDensity());
  }

  @HostBinding('attr.role')
  private get hostRole(): string {
    return 'list';
  }
}
