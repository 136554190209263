@if (mediaTemplate) {
  <div class="bds-card__media bds-card--stretch">
    <ng-content select="[bds-slot-media]"></ng-content>
  </div>
}
@if (heading()) {
  <header class="bds-card__header">
    <h3 class="bds-card__heading" [id]="headingID">{{ heading() }}</h3>
  </header>
}
<div class="bds-card__body">
  <ng-content></ng-content>
</div>
@if (footerTemplate) {
  <footer class="bds-card__footer">
    <ng-content select="[bds-slot-footer]"></ng-content>
  </footer>
}
