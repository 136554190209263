import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  HostBinding,
  input,
  TemplateRef,
} from '@angular/core';
import { BdsSlotFooterDirective, BdsSlotMediaDirective } from '@boels-experience/core';

@Component({
  selector: 'bds-card',
  standalone: true,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  public heading = input<string>();
  public headingID = 'bds-card-heading-' + Math.random().toString(36).substring(2);
  public stretchMedia = input<string | boolean>(null, {
    alias: 'stretch-media',
  });
  public _stretchMedia = computed<boolean>(() => {
    switch (this.stretchMedia()) {
      case 'false':
        return false;
      case '':
        return true;
      default:
        // Evaluate other values with default JS rules
        return !!this.stretchMedia();
    }
  });

  @ContentChild(BdsSlotFooterDirective)
  public footerTemplate: TemplateRef<any> | undefined;

  @ContentChild(BdsSlotMediaDirective)
  public mediaTemplate: TemplateRef<any> | undefined;

  @HostBinding('class') private get hostClassBinding(): string {
    return `bds-card${this._stretchMedia() ? ' bds-card--stretch-media' : ''}`;
  }

  @HostBinding('attr.aria-labelledby')
  private get hostAriaLabelledBy(): string {
    return this.heading() ? this.headingID : null;
  }
}
