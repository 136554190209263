import { Component, effect, ElementRef, inject, input, output, signal, ViewChild } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '@boels-experience/forms';
import { ListItemComponent } from '@boels-experience/layout';
import {
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { QaHooksDirective } from '@boels-experience/core';

@Component({
  selector: 'bds-list-item-input',
  standalone: true,
  imports: [ButtonComponent, MatIcon, FormsModule, ReactiveFormsModule, TranslateModule, QaHooksDirective],
  templateUrl: './list-item-input.component.html',
  styleUrl: './list-item-input.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemInputComponent extends ListItemComponent {
  /**
   * Defines the value displayed within the input field.
   * This value is editable by the user and represents the current input content.
   * @type {InputSignal<string>}
   * @required
   */
  public value = input.required<string>();

  /**
   * Specifies the maximum number of characters the user can enter in the input field,
   * similar to the standard HTML `maxlength` attribute.
   * @type {InputSignal<number>}
   * @default null
   */
  public maxlength = input<number>();

  /**
   * Applies Angular validators to ensure the input value meets specified conditions.
   * These validators prevent the user from saving or submitting the value if it is invalid.
   * @type {InputSignal<Array<ValidatorFn | AsyncValidatorFn>>}
   * @default []
   */
  public validators = input<Array<ValidatorFn | AsyncValidatorFn>>([]);

  /**
   * Event triggered when the user initiates editing within the input field.
   * Can be used to handle UI changes or actions that should occur when editing starts.
   * @type {OutputEmitterRef<void>}
   */
  public onEdit = output();

  /**
   * Event triggered when the user cancels the editing process.
   * Can be used to revert changes or reset the input field state.
   * @type {OutputEmitterRef<void>}
   */
  public onCancel = output();

  /**
   * Event triggered when the user submits the value in the input field.
   * Emits the final submitted value, allowing other components to handle it as needed.
   * @type {OutputEmitterRef<string>}
   */
  public onSubmit = output<string>();

  @ViewChild('inputElement', { static: false })
  private readonly inputElement!: ElementRef;

  protected override get instanceHostClasses(): string {
    return `-input bds-list-item--${this.editActive() ? 'active' : 'inactive'} `;
  }

  public editActive = signal(false);

  public form = new FormGroup({
    value: new FormControl(null, []),
  });

  private readonly updateValidators = effect(() => {
    this.form.controls['value'].setValidators([...this.validators()]);
    this.form.controls['value'].updateValueAndValidity();
  });

  public edit(): void {
    this.form.reset();
    this.form.controls['value'].setValue(this.value());

    this.onEdit.emit();

    this.editActive.set(true);
    this.focusInput();
  }

  private focusInput(): void {
    setTimeout(() => {
      if (this.inputElement?.nativeElement) {
        this.inputElement.nativeElement.focus();
      }
    });
  }

  public cancel(): void {
    this.onCancel.emit();
    this.editActive.set(false);
  }

  public submit(): void {
    this.onSubmit.emit(this.form.controls['value'].value);
    this.editActive.set(false);
  }

  public onKeyup(event: KeyboardEvent): void {
    // Check if the pressed key is "Enter" and the form is valid
    if (event.key === 'Enter' && this.form.valid) {
      this.submit();
    } else if (event.key === 'Escape') {
      this.cancel();
    }
  }
}
