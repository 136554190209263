<span class="bds-list-item__label" qa="bds-list-item-label">
  {{ label() }}
  @if (labelSuffixComponent) {
    <span class="bds-list-item__label-suffix">
      <ng-content select="[bds-slot-label-suffix]"></ng-content>
    </span>
  }
</span>
<span class="bds-list-item__content"><ng-content></ng-content></span>
@if (actionComponent) {
  <span class="bds-list-item__action">
    <ng-content select="[bds-slot-action]"></ng-content>
  </span>
}
