<header class="site-header">
  <div class="site-header__top-wrapper content-grid">
    <div class="site-header__top">
      <section class="site-header__section site-header__top-left -SiteContextSlot" id="SiteContextSlot">
        <ng-content select="[SiteContextSlot]"></ng-content>
      </section>

      <section class="site-header__section site-header__top-right -SiteLinksSlot">
        <!-- section only ever active for logged out users in dotcom -->
        <ng-content select="[SiteLinksSlot]"></ng-content>
      </section>
    </div>
  </div>

  <div class="site-header__middle-wrapper content-grid">
    <div class="site-header__middle">
      <section class="site-header__middle-item site-header__section -SiteLogoSlot">
        <ng-content select="[SiteLogoSlot]"></ng-content>
      </section>

      <section class="site-header__middle-item site-header__middle-item-searchbox site-header__section -SearchBoxSlot">
        <ng-content select="[SearchBoxSlot]"></ng-content>
      </section>

      <section
        class="site-header__middle-item site-header__middle-item-login-indicator site-header__section -SiteLoginSlot"
      >
        <ng-content select="[SiteLoginSlot]"></ng-content>
      </section>

      <section class="site-header__middle-item site-header__section -MiniCartSlot">
        <ng-content select="[MiniCartSlot]"></ng-content>
      </section>
    </div>
  </div>

  <div class="site-header__bottom-wrapper">
    <div class="site-header__bottom">
      <section class="site-header__section content-grid -NavigationBarSlot">
        <ng-content select="[NavigationBarSlot]"></ng-content>
      </section>
    </div>
  </div>

  <section class="site-header__section -HeaderLinksSlot">
    <!-- data not used in dotcom -->
    <ng-content select="[HeaderLinksSlot]"></ng-content>
  </section>
  <section class="site-header__section -BottomHeaderSlot">
    <ng-content select="[BottomHeaderSlot]"></ng-content>
  </section>
  <section class="site-header__section -PlaceholderContentSlot">
    <!-- outputs a section with no actual content on homepage in dotcom -->
    <ng-content select="[PlaceholderContentSlot]"></ng-content>
  </section>
</header>
